import { React, useState, useEffect, cloneElement, useRef } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import SessionManager from "../auth/SessionManager";
import { Fade } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import CompleteCommandCell from '../controls/CompleteCommandCell';
import CommandCell from '../controls/CommandCell';
import DropDownCell from "../controls/DropDownCell";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { useParams, useNavigate, Link } from "react-router-dom"
import { getBaseURLAPI } from '../AccessAPI';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Loader } from "@progress/kendo-react-indicators";
import ImgCell from '../controls/ImgCell';
import Validators from '../controls/Validators';


const initialSort = [{
    field: 'nom',
    dir: 'asc'
}];
const editField = "inEdit";
const editedColor = 'Green';
const defaultColor = 'Black';
const compressImages = false;





function ConstatInt({ t }) {

    let { id } = useParams();
    const txtPasDePiece = 'Pas de piece';
    const [title, setTitle] = useState(t('CreateConstatIntTitle'));
    const [mandatId, setMandatId] = useState('');
    const [mandatTitle, setMandatTitle] = useState('');
    const [parcelleTitle, setParcelleTitle] = useState('');
    const [sort, setSort] = useState(initialSort);
    const [zones, setZones] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();
    const [imagePath, setImagePath] = useState('');
    const [typeZoneData, setTypeZoneData] = useState([]);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    useEffect(() => {
        try {
           
            console.log(window.innerWidth);

            setIsSmallScreen(window.innerWidth < 800 ? true : false);

        } catch (e) { console.log(e); }
    }, []);

    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    };
    const reloadFromDb = () => {
        if (reloading)
            setReloading(false);
        else
            setReloading(true);
    };

    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const [editID, setEditID] = useState(null);

    const rowClick = (event) => {
        //setEditID(event.dataItem.id);
    };

    const remove = async (dataItem) => {
        console.log('remove');
        var result = await MandatManager.deleteZone(dataItem);
        if (result) {
            const newData = [...zones];
            const indexToDelete = newData.findIndex(x => x.id == dataItem.id);
            newData.splice(indexToDelete, 1);
            const newList = zones.filter(x => x.id != dataItem.id);
            setZones(newList);
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });
        }
    };

    const add = async (dataItem) => {

        console.log('add zone ' + dataItem.isNewItem);


        const hasValidationError = dataItem["NameValid"];
        if (hasValidationError)
        {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t(hasValidationError) });
            return;
        }
        var zonesSameName = zones.filter((item) => item.nom == dataItem.nom);
        if (zonesSameName && zonesSameName.length > 1)
        {
            setNotificationState({ ...notificationState, error: true, notificationMessage: 'Zone ' + dataItem.nom + ' existe deja !' });
        }
        else {


            dataItem.inEdit = false;
            dataItem.isNewItem = false;
            setEditID(null);
            const result = await MandatManager.updateZone(dataItem);
            if (result) {
                let zonesNew = zones.map((item) =>
                    item.nom === dataItem.nom
                        ? {
                            ...item,
                            id: result.id,
                            inEdit: false,
                            isNewItem: false,
                            label: dataItem.nom,
                            isEditedByUser: result.isEditedByUser,
                            NameValid: Validators.nameValidator('')
                        }
                        : item);
                setZones(orderBy(zonesNew, sort));
                setNotificationState({ ...notificationState, error: false, notificationMessage: t('SuccessSavingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject') + '\n' + t('ReloadingData') });
                reloadFromDb();
            }
        }
       
    };

    const update = async (dataItem) => {

        console.log('update zone');


        const hasValidationError = dataItem["NameValid"];
        if (hasValidationError) {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t(hasValidationError) });
            return;
        }

        var zonesSamePosition = zones.filter((item) => item.nom == dataItem.nom);
 
        if (zonesSamePosition && zonesSamePosition.length > 1)
        {
            setNotificationState({ ...notificationState, error: true, notificationMessage: 'Zone ' + dataItem.nom + ' existe deja !' });
        }
        else
        {
            dataItem.inEdit = false;
            setEditID(null);
            const result = await MandatManager.updateZone(dataItem);
            if (result) {
                let zonesNew = zones.map((item) =>
                    item.id === dataItem.id
                        ? {
                            ...item,
                            inEdit: false,
                            isNewItem: false,
                            label: dataItem.nom,
                            isEditedByUser: result.isEditedByUser
                        }
                        : item);

                setZones(orderBy(zonesNew, sort));
                refresh();
                setNotificationState({ ...notificationState, error: false, notificationMessage: t('SuccessSavingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject') + '\n' + t('ReloadingData') });
                reloadFromDb();
            }
        
        
        }

    };

    const discard = () => {
        console.log('discard');
        const newData = [...zones];
        newData.splice(0, 1);
        setZones(newData);
    };

    const cancel = (dataItem) => {
        console.log('cancel');
        dataItem.inEdit = false;
        setEditID(null);
        setZones(zones.map((item) =>
            item.id === dataItem.id
                ? {
                    ...item,
                    inEdit: false,
                }
                : item
        ));
        reloadFromDb();
        refresh();
    };

    const enterEdit = (dataItem) => {
        console.log('enterEdit');
        let editedData = zones.map((item) =>
            item.id === dataItem.id
                ? {
                    ...item,
                    inEdit: true,
                    NameValid: Validators.nameValidator(item.nom),
                }
                : item
        );

        setEditID(dataItem.id);
        setZones(editedData);
        refresh();
    };

    const openDetailZone = async (dataItem) => {
        navigateToZone(dataItem);
    };

    const itemChange = (event) => {
        console.log('itemChange');
        const inEditID = event.dataItem.id;
        const field = event.field || "";

        const newData = zones.map((item) =>
            item.id === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                    label: item.nom,
                    desc: txtPasDePiece,
                    NameValid: Validators.nameValidator(event.value)
                }
                : item
        );
        setZones(newData);
    };

    const closeEdit = (event) => {
        console.log('closeEdit');
        if (event.target === event.currentTarget) {
            setEditID(null);
        }
    };

    const addRecord = async () => {
        console.log('addRecord');
        let maxIdZone = zones.length == 0 ? { id: 0 } : zones.reduce((max, zone) => max.id > zone.id ? max : zone);

        let editedData = zones.filter((item) => item.inEdit == true);
        if (editedData && editedData.length > 0) {
            var zonesSameName = zones.filter((item) => item.nom === editedData[0].nom);
            if (zonesSameName.length > 1) {
                setNotificationState({ ...notificationState, error: true, notificationMessage: 'zone ' + editedData[0].nom + ' existe deja!' });
                let data = zones.map((item) => (item.label != '' ? {
                    ...item,
                    inEdit: false,
                    desc: txtPasDePiece
                } : item)
                );
                setZones(data);
                return;

            }
            if (editedData[0].nom == '')
                return;
        }
        const newRecord = {
            id: maxIdZone.id + 1,
            idConstat: id,
            nom: '',
            inEdit: true,
            isNewItem: true,
            idType: typeZoneData[0].id
        };

        setZones([newRecord, ...zones]);
        setEditID(newRecord.id);

    };

    const rowRenderZone = (trElement, dataItem, myProp) => {
        const trProps = {
            ...trElement.props,
            onDoubleClick: async () => {
                if (!dataItem.dataItem.inEdit) {
                    console.log('Double click on ' + dataItem.dataItem.id, myProp)

                    openDetailZone(dataItem.dataItem);
                }



            }, 
            //onContextMenu: (e) => {
            //    e.preventDefault()
            //    console.log('Right Click on ' + dataItem.dataItem.id, myProp)
            //},
            style: { color: dataItem.dataItem.isEditedByUser ? editedColor : defaultColor }
        };
        return cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const navigateToZone = (dataItem) => {
        navigate("/constatint/" + dataItem.idConstat + "/zone/" + dataItem.id);
    }


    const mapZones = (listZones, loadedFromDb, basePath) => {
        console.log(listZones);
        let zonesEdit = listZones.map((item) =>(
            {
                ...item,
                inEdit: false,
                label: item.nom,
                desc: item.pieces && item.pieces.length > 0 ? 'Pieces (' + item.pieces.length + ')' : txtPasDePiece,
                loadedFromDb: loadedFromDb,
                imagePath: item.imagePath != null ? basePath + item.imagePath.slice(1) : "",
                NameValid: '',
                pieces: item.pieces.map((itemPiece) => (
                    {
                        ...itemPiece,
                        imagePath: itemPiece.imagePath != null ? basePath + itemPiece.imagePath.slice(1) : ""
                    }))
            }));
      
        return zonesEdit;
    };


    const fetchZoneType = async () => {
        MandatManager.getCatalogValuesList('zonetype')
            .then(function (result) {
                if (result) {
                    setTypeZoneData(result);
                }
            })
            .catch(function (error) {

                console.log(error);
            });
    };


    const fetchConstatData = async () => {
        console.log('fetch mandat detail');  
        
        let basePath = await getBaseURLAPI();
        MandatManager.getConstatIntDetail(id, compressImages)
            .then(function (result) {

                if (result) {
                    setMandatId(result.idMandat);
                    setMandatTitle(result.mandatDescription);
                    setParcelleTitle(result.parcelleDescription);
                    if (result.imagePath && result.imagePath.length > 1)
                        setImagePath(basePath + result.imagePath.slice(1));

                    let zonesEdit = mapZones(result.zones, true, basePath);
                    console.log(zonesEdit);
                    setZones(orderBy(zonesEdit, sort));
                    setLoading(false);
                    setSort(sort);


                   
                }
            })
            .catch(function (error) {
                setLoading(false);
                setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                console.log(error);
                setErrors(error.message);
            });
    };


    const TypeZoneCell = (props) => (
        <DropDownCell style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word' }}
            {...props}
            dataItems={typeZoneData}

        />
    );


    const ImageCell = (props) => (
        <ImgCell
            {...props}

        />
    );



    useEffect(() => {
        fetchZoneType()
            .then(fetchConstatData());

    
    }, [reloading]);

    const expandChange = (event) => {
        let zonesNew = zones.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setZones(zonesNew);
    };

    const ZoneCommandCell = (props) => (
        <CompleteCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
            detail={openDetailZone}
            canOpenDetail={true} //{props.dataItem && props.dataItem.loadedFromDb}
            title={t('zone')}
            canEdit={true }
        />
    );


    const ZoneDetailComponent = (props) => {

        const navigate = useNavigate();
        const rowRenderPiece = (trElement, dataItem, myProp) => {
            const nullImage = dataItem.dataItem.imagePath == null || dataItem.dataItem.imagePath == "undefined" || dataItem.dataItem.imagePath.length==0;
            const defaut = {
                backgroundColor: "#fff",
            };
            const red = {
                backgroundColor: "rgb(243, 23, 0, 0.32)",
            };
            

            const trProps = {
                ...trElement.props,
                onDoubleClick: () => {
                    if (!dataItem.dataItem.inEdit) {
                        console.log('Double click on ' + dataItem.dataItem.id, myProp)
                        openDetailPiece(dataItem.dataItem);
                    }
                },
                //onContextMenu: (e) => {
                //    e.preventDefault()
                //    console.log('Right Click on ' + dataItem.dataItem.id, myProp)
                //},
                style: nullImage ? red : defaut,
            };
            return cloneElement(trElement, { ...trProps }, trElement.props.children);
        }
        const [pieces, setPieces] = useState(props.dataItem.pieces);

        const PieceCommandCell = (props) => (
            <CommandCell
                {...props}
                remove={removePiece}
                detail={openDetailPiece}
                canEdit={false}
                title={t('Piece')}
            />
        );
        const removePiece = async (dataItemPiece) => {
            var result = await MandatManager.deletePiece(dataItemPiece);
            if (result) {
                const newPieces = [...pieces];
                const indexToDelete = newPieces.findIndex(x => x.id == dataItemPiece.id);
                newPieces.splice(indexToDelete, 1);
                const newList = pieces.filter(x => x.id != dataItemPiece.id);
                setPieces(newList);

                let newZones = zones.map((itemZone) => {

                    if (dataItemPiece.idZone === itemZone.id) {
                        itemZone.pieces = newList;
                    }
                    return itemZone;
                });
                let zonesEdit = mapZones(newZones);
                setZones(zonesEdit);

                setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });

            }
        };

        const openDetailPiece = async (dataItem) => {
            navigateToPiece(dataItem);
           
        }

        const navigateToPiece = (dataItem) => {
            navigate("/constatint/" + id + "/zone/" + dataItem.idZone + "/piecemurs/" + dataItem.id);
        }

        return (
            <Grid data={pieces}
                rowRender={(trElement, dataItem) => rowRenderPiece(trElement, dataItem, { myProp: "test" })}
                resizable={true}         >
             

                <Column field="nom" filterable={false} title={t('Piece')} minWidth={100} />
                <Column field="niveau" title={t('Etage')} minWidth={100} />
                {/*<Column cell={ImageCell} width={50}></Column>*/}
                {!isSmallScreen && <Column field="description" filterable={false} title={t('Description')} />}
                <Column cell={PieceCommandCell} minWidth={50} maxWidth={50} />
            </Grid>
        );

        return (
            <div
                style={{
                    height: "50px",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                    }}
                >
                  </div>
            </div>
        );
    };

    let contents = loading
        ? <div>
            <p><em>{t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>
            {/*<Loader type="infinite-spinner" size="large" themeColor="success"></Loader>*/}
        </div>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                        whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 10 }}>
                    <img src={imagePath} style={{ maxHeight: 200, maxWidth: 200 }} />
                    <Grid
                        id="ZonesGrid"                       
                        //data={orderBy(zones, sort)}
                        data={zones.map((item) => ({
                            ...item,
                            inEdit: item.id === editID,
                        }))}
                        dataItemKey={"id"}
                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}

                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setZones(orderBy(zones, e.sort));
                                refresh();

                            }
                        }

                        editField="inEdit"
                        onRowClick={rowClick}
                        onItemChange={itemChange}
                        detail={ZoneDetailComponent}

                        expandField="expanded"
                        onExpandChange={expandChange}
                        rowRender={(trElement, dataItem) => rowRenderZone(trElement, dataItem, { myProp: "test" })}
                        resizable={true}
                    >
                        <GridToolbar>
                            <div onClick={closeEdit}>
                                <button
                                    title={t('Add')}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    onClick={addRecord}
                                >
                                    {t('Add')}
                                </button>
                            </div>
                         

                        </GridToolbar>

                        <Column field="nom" filterable={false} title={t('Zone')} width={100} />
                        {/*<Column cell={ImageCell} width={50}></Column>*/}
                        <Column field="idType" title={t('Type')} cell={TypeZoneCell} minWidth={100}
                            style={{
                                width: "150px", wordWrap: "break-word", whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                            }} />
                        <Column cell={ZoneCommandCell} width={150} />

                    </Grid>
                </div>

            </div>
            : <p><em>{t(errors)}</em></p>;


    return (
        <div>

            <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{title}</h4>

            <Link to={'/constatint/' + id}>
                <p style={{ marginLeft: 10 }}><i>{t('Parcelle :')} {parcelleTitle} </i></p>
            </Link>
            <Link to={'/mandatdetail/' + mandatId}>
                <p style={{ marginLeft: 10 }}><i>{t('Mandat :')} {mandatTitle} </i></p>
            </Link>

            {contents}
            
        </div>
    );
}
export default withTranslation()(ConstatInt);
