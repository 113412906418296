import { React, useState, useEffect, cloneElement , useRef} from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Error } from "@progress/kendo-react-labels";
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import { Fade } from "@progress/kendo-react-animation";
import CompleteCommandCell from '../controls/CompleteCommandCell';
import CommandCell from '../controls/CommandCell';
import DropDownCell from "../controls/DropDownCell";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { useParams, useNavigate, Link } from "react-router-dom"
import { getBaseURLAPI } from '../AccessAPI';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Loader } from "@progress/kendo-react-indicators";
import { isMobile } from 'react-device-detect';
const initialSort = [{
    field: 'nom',
    dir: 'asc'
}];
const editField = "inEdit";
const editedColor = 'Green';
const defaultColor = 'Black';
const compressImages = false;
import ImgCell from '../controls/ImgCell';
import Validators from '../controls/Validators';

function Zone({ t }) {

    let { zoneid } = useParams();
    const [title, setTitle] = useState(t('CreateZoneTitle'));
    const [zoneTitle, setZoneTitle] = useState('');
    const [constatId, setConstatId] = useState('');
    const [mandatId, setMandatId] = useState('');
    const [mandatTitle, setMandatTitle] = useState('');
    const [parcelleTitle, setParcelleTitle] = useState('');
    const [sort, setSort] = useState(initialSort);
    const [pieces, setPieces] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();
    const [imagePath, setImagePath] = useState('');
    const [niveauData, setNiveauData] = useState([]);
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    };
    const reloadFromDb = () => {
        if (reloading)
            setReloading(false);
        else
            setReloading(true);
    };

    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const [editID, setEditID] = useState(null);

    const rowClick = (event) => {
        //setEditID(event.dataItem.id);
    };

    const remove = async (dataItem) => {
        console.log('remove');
        var result = await MandatManager.deletePiece(dataItem);
        if (result) {
            const newData = [...pieces];
            const indexToDelete = newData.findIndex(x => x.id == dataItem.id);
            newData.splice(indexToDelete, 1);
            const newList = pieces.filter(x => x.id != dataItem.id);
            setPieces(newList);
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });
        }
    };

    const add = async (dataItem) => {

        console.log('add piece ' + dataItem.isNewItem);

        const hasValidationError = dataItem["NameValid"];
        if (hasValidationError) {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t(hasValidationError) });
            return;
        }

        var piecesSameName = pieces.filter((item) => item.nom == dataItem.nom);
        if (piecesSameName && piecesSameName.length > 1) {
            setNotificationState({ ...notificationState, error: true, notificationMessage: 'Piece ' + dataItem.nom + ' existe deja !' });
            //reloadFromDb();
        }
        else {
            dataItem.inEdit = false;
            dataItem.isNewItem = false;
            setEditID(null);

            const result = await MandatManager.updatePiece(dataItem);
            if (result) {
                let piecesNew = pieces.map((item) =>
                    item.nom === dataItem.nom
                        ? {
                            ...item,
                            id: result.id,
                            inEdit: false,
                            isNewItem: false,
                            label: dataItem.nom,
                            idNiveau: dataItem.idNiveau ? dataItem.idNiveau : niveauData[0].id,
                            isEditedByUser: result.isEditedByUser
                        }
                        : item);
                setPieces(orderBy(piecesNew, sort));
                setNotificationState({ ...notificationState, error: false, notificationMessage: t('SuccessSavingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject') + '\n' + t('ReloadingData') });
                reloadFromDb();
            }

        } 
       
    };

    const update = async (dataItem) => {
        console.log('update piece');
        const hasValidationError = dataItem["NameValid"];
        if (hasValidationError) {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t(hasValidationError) });
            return;
        }

        var piecesSameName = pieces.filter((item) => item.nom == dataItem.nom);
        if (piecesSameName && piecesSameName.length > 1) {
            setNotificationState({ ...notificationState, error: true, notificationMessage: 'Piece "' + dataItem.nom + '" existe deja !' });
            //reloadFromDb();
        }
        else {
            dataItem.inEdit = false;
            setEditID(null);
       
            const result = await MandatManager.updatePiece(dataItem);
            if (result) {
                let piecesNew = pieces.map((item) =>
                    item.id === dataItem.id
                        ? {
                            ...item,
                            inEdit: false,
                            isNewItem: false,
                            label: dataItem.nom,

                            isEditedByUser: result.isEditedByUser
                        }
                        : item);

                setPieces(orderBy(piecesNew, sort));
                refresh();
                setNotificationState({ ...notificationState, error: false, notificationMessage: t('SuccessSavingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: 'Piece ' + dataItem.nom + ' existe deja !' });
                reloadFromDb();
            }
        }
       

    };

    const discard = () => {
        console.log('discard');
        const newData = [...pieces];
        newData.splice(0, 1);
        setPieces(newData);
    };

    const cancel = (dataItem) => {
        console.log('cancel');
        dataItem.inEdit = false;
        setEditID(null);
        setPieces(pieces.map((item) =>
            item.id === dataItem.id
                ? {
                    ...item,
                    inEdit: false,
                }
                : item
        ));
        reloadFromDb();
        refresh();
    };

    const enterEdit = (dataItem) => {
        console.log('enterEdit');
        let editedData = pieces.map((item) =>
            item.id === dataItem.id
                ? {
                    ...item,
                    inEdit: true,

                    NameValid: Validators.nameValidator(item.nom),
                }
                : item
        );

        setEditID(dataItem.id);
        setPieces(editedData);
        refresh();
    };

    const openDetailPiece = async (dataItem) => {
        navigateToPieceDetail(dataItem);
    };
    const openPieceMurs = async (dataItem) => {
        navigateToPieceMurs(dataItem);
    };
    const itemChange = (event) => {
        console.log('itemChange');
        const inEditID = event.dataItem.id;
        const field = event.field || "";

        //if (field === "nom") {
        //    event.dataItem.NameValid = Validators.nameValidator(event.value);
        //}

        //event.dataItem[field] = event.value;
        const newData = pieces.map((item) =>
            item.id === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                    label: item.nom,
                    NameValid : Validators.nameValidator(event.value)
                }
                : item
        );
        setPieces(newData);
    };

    const closeEdit = (event) => {
        console.log('closeEdit');
        if (event.target === event.currentTarget) {
            setEditID(null);
        }
    };

    const addPiece = async () => {
        console.log('addPiece');
        let maxIdPiece = pieces.length == 0 ? { id: 0 } : pieces.reduce((max, piece) => max.id > piece.id ? max : piece);

        let editedData = pieces.filter((item) => item.inEdit == true);
        if (editedData && editedData.length > 0) {

            if (editedData[0].nom == '')
                return;
        }
        const newRecord = {
            id: maxIdPiece.id + 1,
            idZone: zoneid,
            nom: '',
            inEdit: true,
            isNewItem: true,
            idNiveau: niveauData[0].id,
            NameValid: Validators.nameValidator('')
        };

        setPieces([newRecord, ...pieces]);
        setEditID(newRecord.id);

    };



    const rowRenderPiece = (trElement, dataItem, myProp) => {
        const trProps = {
            ...trElement.props,
            onDoubleClick: async () => {
                if (!dataItem.dataItem.inEdit) {
                    console.log('Double click on ' + dataItem.dataItem.id, myProp)
                    openPieceMurs(dataItem.dataItem);
                }
            },

            //onContextMenu: (e) => {
            //    e.preventDefault();
            //    console.log('Right Click on ' + dataItem.dataItem.id, myProp)
            //},
            style: { color: dataItem.dataItem.isEditedByUser ? editedColor : defaultColor }
        };
        return cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const navigateToPieceDetail = (dataItem) => {
        navigate("/constatint/" + constatId + "/zone/" + dataItem.idZone + "/piecedetail/" + dataItem.id);
    }
    const navigateToPieceMurs = (dataItem) => {
        navigate("/constatint/" + constatId + "/zone/" + dataItem.idZone + "/piecemurs/" + dataItem.id);
    }

    const mapPieces = (listPieces, loadedFromDb, basePath, setImage) => {
        console.log(listPieces);
        let piecesEdit = listPieces.map((item) => (
            {
                ...item,
                inEdit: false,
                label: item.nom,
                loadedFromDb: loadedFromDb,
                imagePath:  setImage? item.imagePath != null ? basePath + item.imagePath.slice(1) : "": item.imagePath,
                murs: item.murs!=null? item.murs.map((itemMur) => (
                    {
                        ...itemMur,
                        imagePath: setImage ? itemMur.imagePath != null ? basePath + itemMur.imagePath.slice(1) : "" : itemMur.imagePath,
                        timestamp: setImage ? Date.now().toString(): itemMur.timestamp
                    })) : [],
                timestamp: setImage ? Date.now().toString() : item.timestamp,
                NameValid :''
            }));

        return piecesEdit;
    };

    const fetchZoneData = async () => {
        console.log('fetch zone detail');
        let basePath = await getBaseURLAPI();
        MandatManager.getZoneDetail(zoneid, compressImages)
            .then(function (result) {

                if (result) {
                    setZoneTitle(result.nom);
                    setMandatId(result.idMandat);
                    setConstatId(result.idConstat);
                    setMandatTitle(result.mandatDescription);
                    setParcelleTitle(result.parcelleDescription);
                    if (result.imagePath && result.imagePath.length > 1)
                        setImagePath(basePath + result.imagePath.slice(1));

                    let piecesEdit = mapPieces(result.pieces, true, basePath, true);
                    console.log(piecesEdit);
                    setPieces(orderBy(piecesEdit, sort));
                    setLoading(false);
                    setSort(sort);
                }
            })
            .catch(function (error) {
                setLoading(false);
                setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                console.log(error);
                setErrors(error.message);
            });
    };



    const fetchNiveauType = async () => {
        console.log('fetch niveau type');
        MandatManager.getCatalogValuesList('niveautype')
            .then(function (result) {
                if (result) {
                    setNiveauData(result);
                }
            })
            .catch(function (error) {

                console.log(error);
            });


    };

    useEffect(() => {
        try {
            fetchNiveauType();
      
            console.log(window.innerWidth);

            setIsSmallScreen(window.innerWidth < 800 ? true : false);

        } catch (e) { console.log(e); }

    }, []);

    useEffect(() => {
        fetchZoneData();
        //fetchNiveauType()
        //    .then(fetchZoneData());


    }, [reloading]);

    const expandChange = (event) => {
        let piecesNew = pieces.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setPieces(piecesNew);
    };

    const PieceCommandCell = (props) => (
        <CompleteCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
            detail={openDetailPiece}
            canOpenChildren={!isMobile}
            openChildren={openPieceMurs}
            canOpenDetail={true}
            title={t('Piece')}
            canEdit={true }
        />
    );


    const EtageCell = (props) => (
        <DropDownCell
            {...props}
            dataItems={niveauData}
            
        />
    );


    const ImageCell = (props) => (
        <ImgCell
            {...props}

        />
    );

    const PieceDetailComponent = (props) => {

        const navigate = useNavigate();
        const rowRenderMur = (trElement, dataItem, myProp) => {
            const nullImage = dataItem.dataItem.imagePath == null || dataItem.dataItem.imagePath == 'undefined' || dataItem.dataItem.imagePath.length == 0;
            const defaut = {
                backgroundColor: "#fff",
            };
            const red = {
                backgroundColor: "rgb(243, 23, 0, 0.32)",
            };
            

            const trProps = {
                ...trElement.props,
                onDoubleClick: () => {
                    if (!dataItem.dataItem.inEdit) {
                        console.log('Double click on ' + dataItem.dataItem.id, myProp)
                        openDetailMur(dataItem.dataItem);
                    }
                },
                //onContextMenu: (e) => {
                //    e.preventDefault()
                //    console.log('Right Click on ' + dataItem.dataItem.id, myProp)
                //},
                style: nullImage ? red : defaut,
            };
            return cloneElement(trElement, { ...trProps }, trElement.props.children);
        }
        const [murs, setMurs] = useState(props.dataItem.murs);

        const MurCommandCell = (props) => (
            <CompleteCommandCell
                {...props}
                remove={removeMur}
                detail={openDetailMur}
                canOpenDetail={true }
                canEdit={false}
                title={t('Mur')}
            />
        );
        const removeMur = async (dataItemMur) => {
            var result = await MandatManager.deleteMur(dataItemMur);
            if (result) {
                const newMurs = [...murs];
                const indexToDelete = newMurs.findIndex(x => x.id == dataItemMur.id);
                newMurs.splice(indexToDelete, 1);
                const newList = murs.filter(x => x.id != dataItemMur.id);
                setMurs(newList);

                let newPieces = pieces.map((itemPiece) => {

                    if (dataItemMur.idPiece === itemPiece.id) {
                        itemPiece.murs = newList;
                    }
                    return itemPiece;
                });
                let piecesEdit = mapPieces(newPieces, false, '', false);
                setPieces(piecesEdit);

                setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });

            }
        };

        const openDetailMur = async (dataItem) => {
            navigateToMur(dataItem);
           
        }

        const navigateToMur = (dataItem) => {
            navigate("/constatint/" + constatId + "/zone/" + dataItem.idZone + "/piecemurs/" + dataItem.idPiece + "/mur/"+ dataItem.id);
        }

        return (
            <Grid data={murs}
                rowRender={(trElement, dataItem) => rowRenderMur(trElement, dataItem, { myProp: "test" })}
                resizable={true}            >
               {/* <Column field="id" title="No" />*/}
                <Column field="nom" title="Mur" width={100} />
                <Column cell={ImageCell} width={50}></Column>
                
                {!isSmallScreen && <Column field="description" title="Description" />}          
                <Column cell={MurCommandCell} />
            </Grid>
        );

        return (
            <div
                style={{
                    height: "50px",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                    }}
                >
                  </div>
            </div>
        );
    };


    
  
    let contents = loading
        ? <div>
            <p><em>{t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>
            {/*<Loader type="infinite-spinner" size="large" themeColor="success"></Loader>*/}
        </div>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                        whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 10 }}>
                    <img src={imagePath} style={{ maxHeight: 200, maxWidth: 200 }} />
                   
                    <Grid
                        name="PiecesGrid"
                      
                        data={pieces.map((item) => ({
                            ...item,
                            inEdit: item.id === editID,
                        }))}
                        dataItemKey={"id"}
                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}

                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setPieces(orderBy(pieces, e.sort));
                                refresh();

                            }
                        }

                        editField="inEdit"
                        onRowClick={rowClick}
                        onItemChange={itemChange}
                        detail={PieceDetailComponent}

                        expandField="expanded"
                        onExpandChange={expandChange}
                        rowRender={(trElement, dataItem) => rowRenderPiece(trElement, dataItem, { myProp: "test" })}
                        resizable={true}
                        
                    >
                        <GridToolbar>
                            <div onClick={closeEdit}>
                                <button
                                    title={t('Add')}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    onClick={addPiece}
                                >
                                    {t('Add')}
                                </button>
                            </div>
                         

                        </GridToolbar>

                        <Column field="nom" filterable={false} title={t('Piece')} editor="text" width={100} />
                        <Column field="idNiveau" title={t('Etage')} cell={EtageCell} width={80} />
                        {/*<Column cell={ImageCell} width={50}></Column>*/}
                        {!isSmallScreen &&  <Column field="description" filterable={false} title={t('Description')} />}
                        <Column cell={PieceCommandCell} width="200px" />

                    </Grid>
                </div>

            </div>
            : <p><em>{t(errors)}</em></p>;


    return (
        <div>

            <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{title}</h4>

            <Link to={'/constatint/' + constatId}>
                <p style={{ marginLeft: 10 }}><i>{t('Parcelle :')} {parcelleTitle} </i></p>
            </Link>
            <Link to={'/mandatdetail/' + mandatId}>
                <p style={{ marginLeft: 10 }}><i>{t('Mandat :')} {mandatTitle} </i></p>
            </Link>
            <p style={{ marginLeft: 10 }}><i><b>{zoneTitle} </b></i></p>
            {contents}
          
        </div>
    );
}
export default withTranslation()(Zone);
