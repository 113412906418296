

import Fissure from './Fissure';
import { withTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import ConstatNavigationDetail from './ConstatNavigationDetail';
function FissureDetail({ t }) {


    const params = useParams();

    return (
        <div>
            <br />
            <div>
                < ConstatNavigationDetail params={params} />
                < Fissure params={params} />
            </div>
        </div>
    );
}
export default withTranslation()(FissureDetail);