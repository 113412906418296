import { React, useState, useRef, useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import i18next from 'i18next';
import { useParams, useNavigate, Link, useLocation } from "react-router-dom";
import { getBaseURL } from "../AccessAPI";
import 'react-html5-camera-photo/build/css/index.css';
import { Button } from "@progress/kendo-react-buttons";
import Webcam from 'react-webcam';
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { NumericTextBox, TextBox, TextArea } from "@progress/kendo-react-inputs";
import { isMobile } from 'react-device-detect';
import { Loader } from "@progress/kendo-react-indicators";

import {
    FloatingLabel,
    Label,
    Hint,
    Error,
} from "@progress/kendo-react-labels";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
const videoConstraints = {
    width: 600,
    height: 600,
    facingMode: 'environment',
}

const AutreDegat = ({ params }) => {
    const navigate = useNavigate();
    const [updating, setUpdating] = useState(false);
    const [title, setTitle] = useState(i18next.t('CreateDegatTitle'));
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState([]);
    const [DegatTitle, setDegatTitle] = useState('');
    const [niveauList, setNiveauList] = useState([]);
  //  const [positionList, setPositionList] = useState([]);
    const [picture, setPicture] = useState('');
    const [leftId, setLeftId] = useState(null);
    const [centerId, setCenterId] = useState(null);
    const [rightId, setRightId] = useState(null);
    const [overwriteImage, setOverwriteImage] = useState(false);

    const webcamRef = useRef(null)
    const capture = useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot({ width: 3840, height: 3840 });
        console.log(pictureSrc);
        setOverwriteImage(true);
        setPicture(pictureSrc);
    })
   
   
    const [currentNiveau, setCurrentNiveau] = useState(null);
    const [currentDegat, setCurrentDegat] = useState(null);
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });
    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const onChangeNiveau = (e) => {

        setCurrentDegat(existingValues => ({
            ...existingValues,
            idNiveau: e.target.value.id,
        }));
        setCurrentNiveau(e.target.value);
    };
    const onCloseNiveau = (e) => {

        setCurrentNiveau(null);
    };
 
    const setCurrentPositionDegat = useCallback(
        (val) => {
            setCurrentDegat(existingValues => ({
                ...existingValues,
                idPosition: val,
            }))
        },
        [currentDegat?.position]
    );
  
    const handleChangeDescription = useCallback(
        (e) => {
            setCurrentDegat(existingValues => ({
                ...existingValues,
                description: e.value,
            }))
        },
        [currentDegat?.description]
    );

    const handleFileInput = useRef(null);

    const handleClick = () => {
        handleFileInput.current.click();
    };

    const handleImageChange = (event) => {

        try {
            let file = event.target.files[0];


            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let pictureFromTempFile = reader.result.toString();
                setPicture(pictureFromTempFile);
                setOverwriteImage(true);
                
            };
            reader.onerror = (error) => {
                console.log(error);
                setNotificationState({ ...notificationState, error: true, notificationMessage: i18next.t('Error') });
            };
        }
        catch (e) {
            console.log(e);
            setNotificationState({ ...notificationState, error: true, notificationMessage: i18next.t('Error') });
        }
    };

    const saveDegat = async (navigateToParent) => {
        console.log('Saving Degat');
        const dataItemDegat = {
            idFacade: currentDegat.idFacade?.toString(),
            idMur: currentDegat.idMur?.toString(),
            id: currentDegat.id?.toString(),
            imageString: picture,
            idNiveau: currentDegat.idNiveau?.toString(),      
            idPosition: currentDegat.idPosition?.toString(),
            description: currentDegat.description,
            overwriteImage: overwriteImage
        }
        const result = await MandatManager.updateDegat(dataItemDegat);


        if (result && result.errors == null) {
            setNotificationState({ ...notificationState, success: true, notificationMessage: i18next.t('SuccessSavingObject') });
            if (navigateToParent) {
                if (result.idFacade) {
                    navigateToFacade();
                    refresh();
                }
                else if (result.idMur) {
                    navigateToMur();
                    refresh();
                }
            }
            else {
                await navigateToDegat();
                refresh();
            }
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: i18next.t('ErrorSavingObject') });
        }
    };
    const navigateToFacade = () => {
        sessionStorage.setItem('selectedOptionStored', 0);
        navigate("/constat/" + params.constatid + "/facade/" + currentDegat.idFacade);
    }
    const navigateToMur = () => {
        sessionStorage.setItem('selectedOptionStored', 0);
        navigate("/constatint/" + params.constatid + "/zone/" + currentDegat.mur.idZone + "/piecemurs/" + currentDegat.mur.idPiece + "/mur/" + currentDegat.mur.id);
    }
    const navigateToDegat = async () => {
        console.log('navigateToDegat');

        if (params.numero == "create") {

            window.location.reload(false);
        }
        else {
            if (currentDegat.idFacade) {
                setCurrentOrientation(null);

                navigate("/constat/" + params.constatid + "/facade/" + params.facadeid + "/Degat/create");
            } else {
                setCurrentOrientation(null);
                navigate("/constatint/" + params.constatid + "/zone/" + params.zoneid + "/piecemurs/" + params.pieceid + "/mur/" + params.murid + "/Degat/create");
            }
        }

    }
    const saveDegatAndAddDegat = async () => {
        console.log('saveDegatAndAddDegat');
        saveDegat(false);
    };

    const saveDegatAndReturn = async () => {
        sessionStorage.setItem('selectedOptionStored', 0);
        console.log('saveDegatAndReturn');
        saveDegat(true);

    };
    const refresh = () => {
        console.log('refresh');
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    }
    useEffect(() => {
        
        const fetchPositionType = async () => {
            MandatManager.getCatalogValuesList('positiontype')
                .then(function (result) {
                    if (result) {
                        //setPositionList(result);
                        setLeftId(result.find(x => x.code == 'L').id);
                        setCenterId(result.find(x => x.code == 'C').id);
                        setRightId(result.find(x => x.code == 'R').id);
                    }
                })
                .catch(function (error) {

                    console.log(error);
                });


        };


        const fetchNiveauType = async () => {
            MandatManager.getCatalogValuesList('niveautype')
                .then(function (result) {
                    if (result) {
                        setNiveauList(result);
                    }
                })
                .catch(function (error) {

                    console.log(error);
                });


        };
        const fetchDegat = async () => {
            console.log('fetch Degat detail');
            let baseUrl = await getBaseURL();
            if (params.numero == "create") {

                //new Degat

                if (params.facadeid) {

                    setDegatTitle(title);

                    let newDegat = { idFacade: params.facadeid};
                    setCurrentDegat(newDegat);

                    setOverwriteImage(false);
                    setPicture('');
                    setLoading(false);
                }
                else if (params.murid) {
                    setDegatTitle(title);

                    let newDegat = { idMur: params.murid, idZone: params.zoneid, idPiece: params.pieceid };
                    setCurrentDegat(newDegat);

                    setOverwriteImage(false);
                    setPicture('');
                    setLoading(false);

                }

            }
            else {
                MandatManager.getDegatDetail(params.numero)
                    .then(function (result) {
                        if (result) {
                            setDegatTitle(title + ' ' + result.id);
                            setCurrentDegat(result);

                            if (result.imageString) {
                                setPicture('data:image/png;base64,' + result.imageString);
                                setOverwriteImage(false);
                            }
                            else {

                                var imageUrl = '';
                                if (result.imagePath) {
                                    // Create a timestamp
                                    var timestamp = new Date().getTime();

                                    //remove first character='.'
                                    imageUrl = baseUrl + result.imagePath.slice(1) + "?t=" + timestamp;
                                }
                                setOverwriteImage(false);
                                setPicture(imageUrl);

                            }

                            setLoading(false);
                        }
                    })
                    .catch(function (error) {
                        setLoading(false);
                        setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                        console.log(error);
                        setErrors(error.message);
                    });
            }
        };

        setLoading(true);
        fetchPositionType()
            .then(fetchNiveauType())
            .then(fetchDegat());



    }, [updating]);


    useEffect(() => {
        if (currentDegat && currentDegat.idNiveau)
            setCurrentNiveau(niveauList.find(x => x.id == currentDegat.idNiveau));
        else
            setCurrentNiveau(null);

    }, [niveauList, currentDegat]);

  

    const labelStyle = {
        marginBottom: 0,
        fontSize: 14,
        marginLeft: 10,
    };
    const fieldStyle = {
        marginBottom: 0,
        marginLeft: 10,
        width: 300,
        placeholder: null
    };
    const errorLabelStyle = {
        marginBottom: -10,
        fontSize: 12,
        marginLeft: 10,
        color: "red",
        fontStyle: "italic",

    };
    const validateFields = () => {
        return currentDegat && currentDegat.idFacade ?
            picture &&
            currentNiveau &&
            currentDegat.idNiveau &&  currentDegat.idPosition 
            :
            currentDegat && picture ;
    }

 
    let contentsDegat = loading ?
        <div>
            <p><em>{i18next.t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>

        </div>

        :
        errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{i18next.t(notificationMessage)}</span>
                            </Notification>
                        )}
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span>{i18next.t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>


                <div>


                    <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{DegatTitle}</h4>

                    <div>
                      
                       {currentDegat.idFacade &&
                            <div>
                                <p style={labelStyle}><i>{i18next.t('Niveau')}  </i></p>
                                <ComboBox
                                    required={true}
                                    style={fieldStyle}
                                    data={niveauList}
                                    textField="libelle"
                                    dataItemKey="id"
                                    defaultItem="{niveauList.first()}"
                                    adaptive={true}
                                    value={currentNiveau}
                                    onChange={onChangeNiveau}
                                    clearButton={false}
                                    onClose={onCloseNiveau}
                                />
                                <br />
                                {!currentNiveau && <Label style={errorLabelStyle}>{i18next.t('RequiredField')} </Label>}


                                <p style={labelStyle}><i>{i18next.t('Position')}  </i></p>
                                <Button togglable={true} style={{ margin: 10 }}
                                    selected={currentDegat.idPosition === leftId}
                                    onClick={() => setCurrentPositionDegat(leftId)}>Gauche</Button>
                                <Button togglable={true} style={{ margin: 10 }}
                                    selected={currentDegat.idPosition === centerId}
                                    onClick={() => setCurrentPositionDegat(centerId)}>Centre</Button>
                                <Button togglable={true} style={{ margin: 10 }}
                                    selected={currentDegat.idPosition === rightId}
                                    onClick={() => setCurrentPositionDegat(rightId)}>Droite</Button>
                                <br />
                            </div>
                        }
                        <p style={labelStyle}><i>{i18next.t('Description')}  </i></p>
                        <TextArea style={fieldStyle} rows={3} value={currentDegat.description} onChange={handleChangeDescription} />
                        <br /><br />
                    </div>
                    {!isMobile &&
                        <div>
                            {picture != '' ? (
                                <div>
                                    <Button icon='cancel'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOverwriteImage(true);
                                            setPicture('');
                                        }}
                                    >
                                    </Button>
                                    <br />
                                    <img style={{ height: 300 }} src={picture} key="imageFacade" id="imageFacade" />
                                </div>
                            ) :
                                (<div>
                                    <Button icon='camera'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }}
                                    >
                                    </Button>
                                    <Error>{i18next.t('TakePhoto')} </Error>
                                    <Webcam
                                        audio={false}
                                        height={300}
                                        ref={webcamRef}
                                        width={300}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                        forceScreenshotSourceSize="true"
                                    />
                                </div>
                                )}

                        </div>
                    }

                    {isMobile &&

                        <div>
                            <Button icon='camera' onClick={handleClick}></Button>
                            <br />
                            <label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    ref={handleFileInput}
                                    onChange={handleImageChange}
                                />
                            </label>


                            {picture && <img style={{ height: 300 }} src={picture} />}

                        </div>

                    }
                    <br />



                </div>
            </div>
            : <p><em>{i18next.t(errors)}</em></p>;
    return (
        <div>
            {contentsDegat}


            <Button icon="add" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }}
                disabled={!validateFields()}
                onClick={saveDegatAndAddDegat}>{i18next.t('AddDegat')}
            </Button>

            <br />
            <Button icon="save" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }}
                disabled={!validateFields()}
                onClick={saveDegatAndReturn}>{i18next.t('SaveDegat')}</Button>


        </div>);
}
export default withTranslation()(AutreDegat);
