import { React, useState, useRef, useCallback, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import i18next from 'i18next';
import { useParams, useNavigate, Link } from "react-router-dom";
import 'react-html5-camera-photo/build/css/index.css';
import { getBaseURL } from "../AccessAPI";
import { Button } from "@progress/kendo-react-buttons";
import { Loader } from "@progress/kendo-react-indicators";
import { TextArea } from "@progress/kendo-react-inputs"; 
import Webcam from 'react-webcam';
import { isMobile } from 'react-device-detect';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import {
    FloatingLabel,
    Label,
    Hint,
    Error,
} from "@progress/kendo-react-labels";
import { Fade } from "@progress/kendo-react-animation";
const videoConstraints = {
    width: 600,
    height: 600,
    facingMode: 'environment',
}

function Facade({ t }) {
    let { constatid, facadeid } = useParams();
    const [title, setTitle] = useState(t('CreateFacadeTitle'));
    const [facadeTitle, setFacadeTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const [mandatTitle, setMandatTitle] = useState('');
    const [parcelleTitle, setParcelleTitle] = useState('');
    const navigate = useNavigate();
    const [mandatId, setMandatId] = useState('');
    const [picture, setPicture] = useState('')
    const webcamRef = useRef(null);
    const [currentFacade, setCurrentFacade] = useState(null);
    const [overwriteImage, setOverwriteImage] = useState(false);
    const [errors, setErrors] = useState([]);

    const capture = useCallback(() => {
        const pictureSrc = webcamRef.current.getScreenshot({ width: 3840, height: 3840 });
        //console.log(pictureSrc);
        setOverwriteImage(true);
        setPicture(pictureSrc);
    });
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const labelStyle = {
        marginBottom: 0,
        fontSize: 14,
        marginLeft: 0,
    };
    const fieldStyle = {
        marginBottom: 0,
        marginLeft: 0,
        width: 300,
        placeholder: null
    };


    //const [imageObject, setImageObject] = useState(null);

    const handleFileInput = useRef(null);

    const handleClick = () => {
        handleFileInput.current.click();
    };

    const handleImageChange = (event) => {

        try {
            let file = event.target.files[0];

            //setImageObject({
            //    imagePreview: URL.createObjectURL(file),
            //    imageFile: file,
            //});

            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                let pictureFromTempFile = reader.result.toString();
                setPicture(pictureFromTempFile);
                setOverwriteImage(true);
                //let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
                //if ((encoded.length % 4) > 0) {
                //    encoded += '='.repeat(4 - (encoded.length % 4));
                //}
                //resolve(encoded);
            };
            reader.onerror = (error) => {
                console.log(error);
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('Error') });
            };
        }
        catch (e) {
            console.log(e);
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('Error') });
        }
    };

    const handleChangeDescription = useCallback(
        (e) => {
            setCurrentFacade(existingValues => ({
                ...existingValues,
                description: e.value,
            }))
        },
        [setCurrentFacade]
    );


    const { success, error, warning, info, none, notificationMessage } = notificationState;
    const saveFacade = async () => {
        setLoading(true);

        console.log('Saving facade');
        const dataItemFacade = {
            idConstat: constatid,
            id: facadeid,
            position: currentFacade.position,
            imageString: picture,
            overwriteImage: overwriteImage,
            description: currentFacade.description,
            isEditedByUser:true
        };
        
        const result = await MandatManager.updateFacade(dataItemFacade);

        if (result && result.errors == null && result.imageFileExists) {
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessSavingObject') });

            setLoading(false);

            navigate("/constat/" + constatid);
        }
        else if (result && !result.imageFileExists) {
            //error image file does not exist on server
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ImageFileMissing') });

            setLoading(false);

        }
        else {
            //error object not saved
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject') });

            setLoading(false);

        }
        return result;
    };

    const addDegat = async () => {        
        saveFacade().then(async () => {
        navigate("/constat/" + constatid + "/facade/" + facadeid + "/degat");
        });


       
    };


    useEffect(() => {
        const fetchData = async () => {
            console.log('fetch facade detail');
            let baseUrl = await getBaseURL();
            MandatManager.getFacadeDetail(facadeid)
                .then(function (result) {

                    if (result) {
                        setMandatTitle(result.mandatDescription);
                        setParcelleTitle(result.parcelleDescription);
                        setFacadeTitle(title + ' ' + result.position);


                        if (result.imageString)
                        {
                            setPicture('data:image/png;base64,' + result.imageString);
                            setOverwriteImage(false);
                        }
                        else {

                            var imageUrl = '';
                            if (result.imagePath) {
                                // Create a timestamp
                                var timestamp = new Date().getTime();

                                //remove first character='.'
                                imageUrl = baseUrl + result.imagePath.slice(1) + "?t=" + timestamp;
                            }
                            setOverwriteImage(false);
                            setPicture(imageUrl);

                        }
                        setMandatId(result.idMandat);

                        setCurrentFacade(result);
                        setLoading(false);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    // setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                    console.log(error);
                    setErrors(error.message);
                });
        };
        fetchData();

    }, []);


  

    let contentsFacade = loading ?
        <div>
            <p><em>{i18next.t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>
            {/*<Loader type="infinite-spinner" size="large" themeColor="success"></Loader>*/}
        </div>
        :
        errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <div>{t(notificationMessage)}</div>
                            </Notification>
                        )}                       
                        
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span style={{ whiteSpace: "pre-line", maxWidth: 200 }}>{t(notificationMessage)}</span >
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div>
                    <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{facadeTitle}</h4>


                    <Link to={'/constat/' + constatid}>
                        <p style={{ marginLeft: 10 }}><i>{t('Parcelle :')} {parcelleTitle} </i></p>
                    </Link>
                    <Link to={'/mandatdetail/' + mandatId}>
                        <p style={{ marginLeft: 10 }}><i>{t('Mandat :')} {mandatTitle} </i></p>
                    </Link>
                    
                    {!isMobile && 
                        <div>
                            {picture != '' ? (
                                <div>
                                    <Button icon='cancel'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOverwriteImage(true);
                                            setPicture('');
                                        }}
                                    >
                                    </Button>
                                    <br/>
                                    <img style={{ height: 300 }} src={picture} key="imageFacade" id="imageFacade" />
                                </div>
                            ) :
                                (<div>
                                    <Button icon='camera'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            capture();
                                        }}
                                    >
                                    </Button>
                                    <Error>{t('TakePhoto')} </Error>
                                    <Webcam
                                        audio={false}
                                        height={300}
                                        ref={webcamRef}
                                        width={300}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                        forceScreenshotSourceSize="true"
                                    />
                                </div>
                                )}

                        </div>
                    }

                    {isMobile &&
                       
                        <div>
                            <Button icon='camera' onClick={handleClick}></Button>
                            <br/>
                            <label>
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    ref={handleFileInput}
                                    onChange={handleImageChange}
                                />
                            </label>
                           
                            {/*{imageObject && <img style={{height: 300 }} src={imageObject.imagePreview} />}*/}

                     
                            {picture && <img style={{ height: 300 }} src={picture} />}

                        </div>
                        
                    }
                    <br />

                    <p style={labelStyle} ><i>{t('Description')}  </i></p>
                    <TextArea style={fieldStyle} rows={3} value={currentFacade.description} onChange={handleChangeDescription} />
                    <br />

                    <Button icon="add" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }} disabled={!picture}
                        onClick={addDegat}>{t('AddDegat')}
                    </Button>
                   
                    <br />
                    <Button icon="save" themeColor={"primary"} style={{ marginLeft: 10, marginTop: 10 }} disabled={!picture}
                        onClick={saveFacade}>{t('SaveFacade')}</Button>
                    <br /><br />
                   
                </div>
            </div>
            : <p><em>{t(errors)}</em></p>;;
    return (
        <div>

            {contentsFacade}
        </div>);
}
export default withTranslation()(Facade);
