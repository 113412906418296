import { React, useState, useEffect, cloneElement } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import SessionManager from "../auth/SessionManager";
import { Fade } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import CompleteCommandCell from '../controls/CompleteCommandCell';
import CommandCell from '../controls/CommandCell';
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { useParams, useNavigate, Link } from "react-router-dom"
import { getBaseURLAPI } from '../AccessAPI';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Loader } from "@progress/kendo-react-indicators";

const initialSort = [{
    field: 'position',
    dir: 'asc'
}];
const editField = "inEdit";
const editedColor = 'Green';
const defaultColor = 'Black';
const compressImages = false;
import ImgCell from '../controls/ImgCell';

function Constat({ t }) {

    let { id } = useParams();
    const txtPasDeFissure = t('PasDeFissure') ;
    const txtPasDeDegat = t('PasDeDegat');
    const [title, setTitle] = useState(t('CreateConstatTitle'));
    const [mandatId, setMandatId] = useState('');
    const [mandatTitle, setMandatTitle] = useState('');
    const [parcelleTitle, setParcelleTitle] = useState('');
    const [sort, setSort] = useState(initialSort);
    const [facades, setFacades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [reloading, setReloading] = useState(false);
    const [errors, setErrors] = useState('');
    const navigate = useNavigate();
    const [imagePath, setImagePath] = useState('');
    const [isSmallScreen, setIsSmallScreen] = useState(false);
   
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    };
    const reloadFromDb = () => {
        if (reloading)
            setReloading(false);
        else
            setReloading(true);
    };

    const { success, error, warning, info, none, notificationMessage } = notificationState;

    const [editID, setEditID] = useState(null);

    const rowClick = (event) => {
        //setEditID(event.dataItem.id);
    };

    const remove = async (dataItem) => {
        console.log('remove');
        var result = await MandatManager.deleteFacade(dataItem);
        if (result) {
            const newData = [...facades];
            const indexToDelete = newData.findIndex(x => x.id == dataItem.id);
            newData.splice(indexToDelete, 1);
            const newList = facades.filter(x => x.id != dataItem.id);
            setFacades(newList);
            setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });
        }
    };

    const add = async (dataItem) => {

        console.log('add facade ' + dataItem.isNewItem);
        var facadesSamePosition = facades.filter((item) => item.position == dataItem.position);
        if (facadesSamePosition && facadesSamePosition.length == 1) {
            dataItem.inEdit = false;
            dataItem.isNewItem = false;
            setEditID(null);
            const result = await MandatManager.updateFacade(dataItem);
            if (result) {
                let facadesNew = facades.map((item) =>
                    item.position === dataItem.position
                        ? {
                            ...item,
                            id: result.id,
                            inEdit: false,
                            isNewItem: false,
                            label: dataItem.position,
                            isEditedByUser: result.isEditedByUser
                        }
                        : item);
                setFacades(orderBy(facadesNew, sort));
                setNotificationState({ ...notificationState, error: false, notificationMessage: t('SuccessSavingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject')+'\n'+t('ReloadingData') });
                reloadFromDb();
            }
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: 'Facade ' + dataItem.position + ' existe deja!' });
        }
    };

    const update = async (dataItem) => {
        console.log('update facade');
        var facadesSamePosition = facades.filter((item) => item.position == dataItem.position);
        //const result = await MandatManager.updateFacade(dataItem);
        if (facadesSamePosition && facadesSamePosition.length == 1)
        {
            dataItem.inEdit = false;
            setEditID(null);
            const result = await MandatManager.updateFacade(dataItem);
            if (result) {
                let facadesNew = facades.map((item) =>
                    item.id === dataItem.id
                        ? {
                            ...item,
                            inEdit: false,
                            isNewItem: false,
                            label: dataItem.position,
                            isEditedByUser: result.isEditedByUser
                        }
                        : item);

                setFacades(orderBy(facadesNew, sort));
                refresh();
                setNotificationState({ ...notificationState, error: false, notificationMessage: t('SuccessSavingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorSavingObject') + '\n' + t('ReloadingData') });
                reloadFromDb();
            }
        }
        else {
            setNotificationState({ ...notificationState, error: true, notificationMessage: 'Facade ' + dataItem.position + ' existe deja!' });
        }

    };

    const discard = () => {
        console.log('discard');
        const newData = [...facades];
        newData.splice(0, 1);
        setFacades(newData);
    };

    const cancel = (dataItem) => {
        console.log('cancel');
        dataItem.inEdit = false;
        setEditID(null);
        setFacades(facades.map((item) =>
            item.id === dataItem.id
                ? {
                    ...item,
                    inEdit: false,
                }
                : item
        ));
        refresh();
    };

    const enterEdit = (dataItem) => {
        console.log('enterEdit');
        let editedData = facades.map((item) =>
            item.id === dataItem.id
                ? {
                    ...item,
                    inEdit: true,
                }
                : item
        );

        setEditID(dataItem.id);
        setFacades(editedData);
        refresh();
    };

    const openDetailFacade = async (dataItem) => {
        navigateToFacade(dataItem);
    };

    const DescTextCell = (props) => (
        <td style={{ whiteSpace: 'pre-wrap' }}>
            {props.dataItem.desc}
        </td>
    );



    const itemChange = (event) => {
        console.log('itemChange');
        const inEditID = event.dataItem.id;
        const field = event.field || "";
        //event.dataItem[field] = event.value;
        const newData = facades.map((item) =>
            item.id === inEditID
                ? {
                    ...item,
                    [field]: event.value,
                    label: item.position,
                    desc: txtPasDeFissure + '\n' + txtPasDeDegat,
                    descDegat: txtPasDeDegat
                }
                : item
        );
        setFacades(newData);
    };

    const closeEdit = (event) => {
        console.log('closeEdit');
        if (event.target === event.currentTarget) {
            setEditID(null);
        }
    };

    const addRecord = async () => {
        console.log('addRecord');
        let maxIdFacade = facades.length == 0 ? { id: 0 } : facades.reduce((max, facade) => max.id > facade.id ? max : facade);

        let editedData = facades.filter((item) => item.inEdit == true);
        if (editedData && editedData.length > 0) {
            var facadesSamePosition = facades.filter((item) => item.position === editedData[0].position);
            if (facadesSamePosition.length > 1) {
                setNotificationState({ ...notificationState, error: true, notificationMessage: 'Facade ' + editedData[0].position + ' existe deja!' });
                let data = facades.map((item) => (item.label != '' ? {
                    ...item,
                    inEdit: false,
                    desc: txtPasDeFissure + '\n' + txtPasDeDegat,
                    descDegat: txtPasDeDegat
                } : item)
                );
                setFacades(data);
                return;

            }
            if (editedData[0].position == '')
                return;
        }
        const newRecord = {
            id: maxIdFacade.id + 1,
            idConstat: id,
            position: '',
            inEdit: true,
            isNewItem: true
        };

        setFacades([newRecord, ...facades]);
        setEditID(newRecord.id);

    };



    const rowRenderFacade = (trElement, dataItem, myProp) => {
        const trProps = {
            ...trElement.props,
            onDoubleClick: async () => {
                if (!dataItem.dataItem.inEdit) {
                    console.log('Double click on ' + dataItem.dataItem.id, myProp)

                    openDetailFacade(dataItem.dataItem);
                }



            }, 
            //onContextMenu: (e) => {
            //    e.preventDefault()
            //    console.log('Right Click on ' + dataItem.dataItem.id, myProp)
            //},
            style: { color: dataItem.dataItem.isEditedByUser ? editedColor : defaultColor }
        };
        return cloneElement(trElement, { ...trProps }, trElement.props.children);
    }

    const navigateToFacade = (dataItem) => {
        navigate("/constat/" + id + "/facade/" + dataItem.id);
    }


    const mapFacades = (listFacades, loadedFromDb, basePath, setImage) => {
        console.log(listFacades);
        let facadesEdit = listFacades.map((item) =>(
            {
                ...item,
                inEdit: false,
                label: item.position,
                desc: (item.fissures && item.fissures.length > 0 ? t('Fissures') + ' (' + item.fissures.length + ')' : txtPasDeFissure) +  "\n"  + (item.degats && item.degats.length > 0 ? t('AutreDegats') + ' (' + item.degats.length + ')' : txtPasDeDegat),
                descDegat: item.degats && item.degats.length > 0 ? t('AutreDegats') + '(' + item.degats.length + ')' : txtPasDeDegat,
                loadedFromDb: loadedFromDb,
                imagePath: setImage ? item.imagePath != null ? basePath + item.imagePath.slice(1) : "" : item.imagePath,
                fissures: item.fissures.map((itemFissure) => (
                    {
                        ...itemFissure,
                        imagePath: setImage ? itemFissure.imagePath != null ? basePath + itemFissure.imagePath.slice(1) : "" : itemFissure.imagePath,
                        timestamp: setImage ? Date.now().toString() : itemFissure.timestamp
                    })),
                timestamp: setImage ? Date.now().toString() : item.timestamp
            }));
      
        return facadesEdit;
    };


   

    const fetchConstatData = async () => {
        console.log('fetch mandat detail');
        let basePath = await getBaseURLAPI();
        MandatManager.getConstatDetail(id, compressImages)
            .then(function (result) {

                if (result) {
                    setMandatId(result.idMandat);
                    setMandatTitle(result.mandatDescription);
                    setParcelleTitle(result.parcelleDescription);
                    if (result.imagePath && result.imagePath.length > 1)
                        setImagePath(basePath + result.imagePath.slice(1));

                    let facadesEdit = mapFacades(result.facades, true, basePath, true);
                    console.log(facadesEdit);
                    setFacades(orderBy(facadesEdit, sort));
                    setLoading(false);
                    setSort(sort);
                }
            })
            .catch(function (error) {
                setLoading(false);
                setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                console.log(error);
                setErrors(error.message);
            });
    };

    useEffect(() => {

        fetchConstatData();

    }, [reloading]);

    useEffect(() => {
        try {

            console.log(window.innerWidth);

            setIsSmallScreen(window.innerWidth < 800 ? true : false);

        } catch (e) { console.log(e); }

    }, []);

    const expandChange = (event) => {
        let facadesNew = facades.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });

        setFacades(facadesNew);
    };

    const FacadeCommandCell = (props) => (
        <CompleteCommandCell
            {...props}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
            detail={openDetailFacade}
            canOpenDetail={true} //{props.dataItem && props.dataItem.loadedFromDb}
            title={t('Facade')}
            canEdit={true }
        />
    );

    const ImageCell = (props) => (
        <ImgCell
            {...props}

        />
    );

    const FacadeDetailComponent = (props) => {

        const navigate = useNavigate();
        const rowRenderFissure = (trElement, dataItem, myProp) => {
            const nullImage = dataItem.dataItem.imagePath==null || dataItem.dataItem.imagePath.length==0;
            const defaut = {
                backgroundColor: "#fff",
            };
            const red = {
                backgroundColor: "rgb(243, 23, 0, 0.32)",
            };
            

            const trProps = {
                ...trElement.props,
                onDoubleClick: () => {
                    if (!dataItem.dataItem.inEdit) {
                        console.log('Double click on ' + dataItem.dataItem.id, myProp)
                        openDetailFissure(dataItem.dataItem);
                    }
                },
                //onContextMenu: (e) => {
                //    e.preventDefault()
                //    console.log('Right Click on ' + dataItem.dataItem.id, myProp)
                //},
                style: nullImage ? red : defaut,
            };
            return cloneElement(trElement, { ...trProps }, trElement.props.children);
        }

        const rowRenderDegat = (trElement, dataItem, myProp) => {
            const nullImage = dataItem.dataItem.imagePath == null || dataItem.dataItem.imagePath.length == 0;
            const defaut = {
                backgroundColor: "#fff",
            };
            const red = {
                backgroundColor: "rgb(243, 23, 0, 0.32)",
            };


            const trProps = {
                ...trElement.props,
                onDoubleClick: () => {
                    if (!dataItem.dataItem.inEdit) {
                        console.log('Double click on ' + dataItem.dataItem.id, myProp)
                        openDetailAutreDegat(dataItem.dataItem);
                    }
                },
                style: nullImage ? red : defaut,
            };
            return cloneElement(trElement, { ...trProps }, trElement.props.children);
        }


        const [fissures, setFissures] = useState(props.dataItem.fissures);
        const [degats, setDegats] = useState(props.dataItem.degats);
        const FissureCommandCell = (props) => (
            <CommandCell
                {...props}
                remove={removeFissure}
                detail={openDetailFissure}
                canEdit={false}
                title={t('Fissure')}
            />
        );

        const AutreDegatCommandCell = (props) => (
            <CommandCell
                {...props}
                remove={removeAutreDegat}
                detail={openDetailAutreDegat}
                canEdit={false}
                title={t('AutreDegat')}
            />
        );
        const removeAutreDegat = async (dataItemAutreDegat) => {
            var result = await MandatManager.deleteDegat(dataItemAutreDegat);
            if (result) {
                const newDegats = [...degats];
                const indexToDelete = newDegats.findIndex(x => x.id == dataItemAutreDegat.id);
                newDegats.splice(indexToDelete, 1);
                const newList = degats.filter(x => x.id != dataItemAutreDegat.id);
                setDegats(newList);
                let basePath = await getBaseURLAPI();
                let newFacades = facades.map((itemFacade) => {

                    if (dataItemAutreDegat.idFacade === itemFacade.id) {
                        itemFacade.degats = newList;                       
                    }
                    return itemFacade;
                });
             
                let facadesEdit = mapFacades(newFacades, false, basePath, false);
                setFacades(facadesEdit);

                setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });

            }
        };

        const removeFissure = async (dataItemFissure) => {
            var result = await MandatManager.deleteFissure(dataItemFissure);
            if (result) {
                const newFissures = [...fissures];
                const indexToDelete = newFissures.findIndex(x => x.id == dataItemFissure.id);
                newFissures.splice(indexToDelete, 1);
                const newList = fissures.filter(x => x.id != dataItemFissure.id);
                setFissures(newList);
                let basePath = await getBaseURLAPI();
                let newFacades = facades.map((itemFacade) => {

                    if (dataItemFissure.idFacade === itemFacade.id) {
                        itemFacade.fissures = newList;
                    }
                    return itemFacade;
                });
                let facadesEdit = mapFacades(newFacades, false, basePath, false);
                setFacades(facadesEdit);

                setNotificationState({ ...notificationState, success: true, notificationMessage: t('SuccessDeletingObject') });
            }
            else {
                setNotificationState({ ...notificationState, error: true, notificationMessage: t('ErrorDeletingObject') });

            }
        };

        const openDetailFissure = async (dataItem) => {
            navigateToFissure(dataItem);
           
        }
        const openDetailAutreDegat = async (dataItem) => {
            navigateToAutreDegat(dataItem);

        }
        const navigateToFissure = (dataItem) => {
            navigate("/constat/" + id + "/facade/" + dataItem.idFacade + "/fissure/" +  dataItem.id);
        }
        const navigateToAutreDegat= (dataItem) => {
            navigate("/constat/" + id + "/facade/" + dataItem.idFacade + "/autredegat/" + dataItem.id);
        }

       


        return (
            <div>
                <h3>{t('Fissures')}</h3>
          
          

            <Grid data={fissures}
                rowRender={(trElement, dataItem) => rowRenderFissure(trElement, dataItem, { myProp: "test" })}
                resizable={true}            >
                <Column field="id" title="No" />
                <Column cell={ImgCell} width={50}></Column>
                <Column field="orientationText" title="Orientation" />
                <Column field="positionText" title="Position" />
                <Column field="niveauText" title="Niveau" />
                <Column cell={FissureCommandCell} />
                </Grid>
               
                  
                <h3>{t('AutreDegats')}</h3>
                <Grid data={degats}
                    rowRender={(trElement, dataItem) => rowRenderDegat(trElement, dataItem, { myProp: "degat" })}
                resizable={true}            >
                <Column field="id" title="No" />
                <Column cell={ImgCell} width={50}></Column>
                <Column field="positionText" title="Position" />
                <Column field="niveauText" title="Niveau" />
                <Column cell={AutreDegatCommandCell} />
                </Grid>
            </div>
        );

        return (
            <div
                style={{
                    height: "50px",
                    width: "100%",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        width: "100%",
                    }}
                >
                  </div>
            </div>
        );
    };

    let contents = loading
        ? <div>
            <p><em>{t('Loading')} </em></p>
            <Loader type="converging-spinner" size="medium"></Loader>
            {/*<Loader type="infinite-spinner" size="large" themeColor="success"></Loader>*/}
        </div>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                        whiteSpace: 'pre-wrap', overflowWrap: 'break-word'
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                        {success && (
                            <Notification
                                type={{
                                    style: "success",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, success: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 10 }}>
                    <img src={imagePath} style={{ maxHeight: 200, maxWidth: 200 }} />
                    <Grid
                        name="FacadesGrid"
                        data={facades.map((item) => ({
                            ...item,
                            inEdit: item.id === editID,
                        }))}
                        dataItemKey={"id"}
                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}

                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setFacades(orderBy(facades, e.sort));
                                refresh();

                            }
                        }

                        editField="inEdit"
                        onRowClick={rowClick}
                        onItemChange={itemChange}
                        detail={FacadeDetailComponent}

                        expandField="expanded"
                        onExpandChange={expandChange}
                        rowRender={(trElement, dataItem) => rowRenderFacade(trElement, dataItem, { myProp: "test" })}
                        resizable={true}
                    >
                        <GridToolbar>
                            <div onClick={closeEdit}>
                                <button
                                    title={t('Add')}
                                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                                    onClick={addRecord}
                                >
                                    {t('Add')}
                                </button>
                            </div>
                         

                        </GridToolbar>

                        <Column field="position" filterable={false} title={t('F')} width={55} />
                        <Column cell={ImgCell} width={50}></Column>
                        {/*<Column  filterable={false} title={t('Degats')} editable={false} />*/}
                        <Column field="desc" title={t('Degats')} cell={DescTextCell} filterable={false} editable={false}  />
                        <Column cell={FacadeCommandCell} width="150px" />

                    </Grid>
                </div>

            </div>
            : <p><em>{t(errors)}</em></p>;


    return (
        <div>

            <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom: 5 }}>{title}</h4>

            <Link to={'/constat/' + id}>
                <p style={{ marginLeft: 10 }}><i>{t('Parcelle :')} {parcelleTitle} </i></p>
            </Link>
            <Link to={'/mandatdetail/' + mandatId}>
                <p style={{ marginLeft: 10 }}><i>{t('Mandat :')} {mandatTitle} </i></p>
            </Link>

            {contents}
            
        </div>
    );
}
export default withTranslation()(Constat);
