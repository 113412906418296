import * as React from "react";
import { Button } from "@progress/kendo-react-buttons";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import ConfirmationDialog from './ConfirmationDialog';

const CompleteCommandCell = (props) => {
    const { dataItem } = props;
    const inEdit = dataItem[props.editField];
    //const isNewItem = dataItem.label != dataItem.position;

    let nom = "";
    const [confirmDialog, setConfirmDialog] = React.useState(false);

    const onConfirmDelete = () => {
        setConfirmDialog(false);
        props.remove(dataItem);

    }

    const onClose = () => {
        setConfirmDialog(false);
    }
    if (props.dataItem) {
        nom = props.dataItem.nom ?? props.dataItem.userName ?? props.dataItem.label ?? props.dataItem.id;
    }
    return inEdit ? (
        <td className="k-command-cell">
            <Button icon='save' themeColor={"secondary"}
                onClick={() =>
                    dataItem.isNewItem ? props.add(dataItem) : props.update(dataItem)
                }
            >
            </Button>
            <Button icon='cancel' themeColor={"secondary"}
                onClick={() =>
                    dataItem.isNewItem ? props.discard(dataItem) : props.cancel(dataItem)
                }
            >
            </Button>
        </td>
    ) : (<td className="k-command-cell">

            <Button icon='edit' themeColor={"primary"}
                style={{ visibility: props.canEdit == true ? "visible" : "hidden" }}
            onClick={() => props.edit(dataItem)} >
        </Button>

        <Button icon='delete' themeColor={"primary"}
            onClick={() =>
                setConfirmDialog(true)
            }>
        </Button>

        {confirmDialog &&
            <ConfirmationDialog
                onConfirm={onConfirmDelete}
                onClose={onClose}
                Message={i18next.t('ConfirmDelete') + ' ' + nom + ' ?'}
                Title={i18next.t('Delete') + ' ' + i18next.t(props.title)} />}


        <Button icon='eye' themeColor={"primary"} disabled={props.canOpenDetail == false}
            onClick={() => props.detail(dataItem)}>
            </Button>

            <Button icon='list-unordered' themeColor={"primary"} style={{ visibility: props.canOpenChildren == true ? "visible" : "hidden" }}
                onClick={() => props.openChildren(dataItem)}>
            </Button>
    </td>

    );
};
export default withTranslation()(CompleteCommandCell);
