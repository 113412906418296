import { React, useState, useEffect } from 'react';
import { orderBy } from '@progress/kendo-data-query';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { withTranslation } from 'react-i18next';
import MandatManager from './MandatManager';
import SessionManager from "../auth/SessionManager";
import { Fade } from "@progress/kendo-react-animation";
import { Button } from "@progress/kendo-react-buttons";
import {
    Notification,
    NotificationGroup,
} from "@progress/kendo-react-notification";
import { filterBy } from "@progress/kendo-data-query";
import { useParams, useNavigate } from "react-router-dom";
import { getBaseURL, getBaseURLAPI } from "../AccessAPI";
import TemplatePDFDialog from './TemplatePDF';
import { StackLayout }  from "@progress/kendo-react-layout";

const initialFilter = {
    logic: "and",
    filters: [
        {
            field: "fullTextSearch",
            operator: "contains",
            value: "",
        },
    ],
};
import i18next from 'i18next';




const initialSort = [{
    field: 'numero',
    dir: 'asc'
}];

const filterOperators = {
    text: [
        {
            text: "grid.filterContainsOperator",
            operator: "contains",
        },
    ]

};



const ParcelleDetailComponent = (props) => {
    const dataItem = props.dataItem;
    const [canViewConstat, setCanViewConstat] = useState(false);
    const navigate = useNavigate();
    const [baseUrl, setBaseUrl] = useState('');
    const [openFormTemplate, setOpenFormTemplate] = useState(false);
    const [itemMandat, setItemMandat] = useState("");
    const [selectedValue, setSelectedValue] = useState("SignatureMetriX");
    const [typeConstatPdf, setTypeConstatPdf] = useState("EXT");

    const [dateReleve, setDateReleve] = useState("");
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setBaseUrl(await getBaseURLAPI());
            let userRole = SessionManager.getUserRole();
            if (userRole == 'Administrateur')
                setCanViewConstat(true);
            else
                setCanViewConstat(false);
        }
        fetchData();
    }, []);

    const onClickCreateConstat = async (item) => {
      
        let result = await MandatManager.createConstat(item.id);
        if (result) {
            
            if (result?.id) {
                setErrorMessage('');
                navigate("/constat/" + result.id);
            }
            else {
                setErrorMessage(i18next.t('ErrorCreatingObject'));
            }
        }
        else
        {
            setErrorMessage(i18next.t('ErrorCreatingObject'));
        }
    };
    const onClickViewConstat = async (item) => {
        if (item.constatExt) {
            navigate("/constat/" + item.constatExt.id);
        }
    };
    const onClickCreatePDF = async (item) => {

        setItemMandat(item);
        setSelectedValue("SignatureMetriX");
        setTypeConstatPdf("EXT");
        setOpenFormTemplate(true);
 
    };

    const onClickCreatePDFInternal = async (item) => {

        setItemMandat(item);
        setSelectedValue("SignatureMetriX");
        setTypeConstatPdf("INT");
        setOpenFormTemplate(true);

    };

    const onClickViewConstatInternal = async (item) => {
        if (item.constatInt)
        {
            navigate("/constatint/" + item.constatInt.id);
        }
    };

    const onClickCreateConstatInternal = async (item) => {

        let result = await MandatManager.createConstatInternal(item.id);
        if (result) {

            if (result?.id) {
                setErrorMessage('');
                navigate("/constatint/" + result.id);
            }
            else {
                setErrorMessage(i18next.t('ErrorCreatingObject'));
            }
        }
        else {
            setErrorMessage(i18next.t('ErrorCreatingObject'));
        }
    };
   

    const handleChange = async (e) => {

        setSelectedValue(e.value);

    };

    const handleChangeDateReleve = async (e) => {

        setDateReleve(e.value);

    };
    

    const handleCreatePDF = async () => {

        let dateMDY = '';
        if (dateReleve.toString().length >1)
        {
            let month = dateReleve.getMonth();
            let day = dateReleve.getDate();
            let year = dateReleve.getFullYear();
            month = month + 1;
            if (month.toString().length == 1) {
                month = '0' + month;
            }
            if (day.toString().length == 1) {
                day = '0' + day;
            }
            dateMDY = day + '-' + month + '-' + year;
        }

       
        setDateReleve('');

        const dataToPDF = typeConstatPdf == "EXT" ?
                { IdConstat: itemMandat.constatExt?.id, TypeSignature: selectedValue, DateReleve: dateMDY, IdTypeConstat: itemMandat.constatExt?.idType }
                :
                { IdConstat: itemMandat.constatInt?.id, TypeSignature: selectedValue, DateReleve: dateMDY, IdTypeConstat: itemMandat.constatInt?.idType };

        let result = await MandatManager.createPDF(dataToPDF);
      
        setOpenFormTemplate(false);
      
       let pdfFileURL = await getBaseURLAPI() + "/FilesPdf/" + result ;
        window.open(pdfFileURL);
       
    };


    const handleCancelPDF = () =>
    {
        setOpenFormTemplate(false);
    };



    return (<div key={dataItem.id}>
        <table key={dataItem.id + "1"} className="tableMandat" >
                <tbody>
                    <tr>
                        <td style={{ width: `50%` }}>
                            <section>
                                {dataItem.parcelle.proprietaires.map(personne => <p>{personne?.firstName} {personne?.lastName}</p>)}
                                <p>
                                   {dataItem.parcelle.habitation}
                                </p>
                                <p>
                                   {dataItem.parcelle.bienfond}
                                </p>
                            </section>
                        </td>
                        <td style={{ width: `50%` }}>
                            <section>
                                <img src={baseUrl+props.dataItem.parcelle.orthophotoPath.slice(1)} className="photo"></img>
                              
                            </section>
                        </td>
                       
                </tr>
             
              
                </tbody>
            </table>


        <table className="tableMandat">
            <tbody>
                <tr>
                    <td style={{ width: `100%` }}>

                        <div style={{
                           textAlign:'center', fontWeight:'bold'
                        }}>
                            {errorMessage}

                           
                                <i> {i18next.t('Constat')} </i>
                           
                        </div>


                       
                    </td>
                </tr>
            </tbody>
        </table>
        <table className="tableMandat">
            <tbody>
                <tr>

                    <td style={{
                        width: `50%`, textAlign:'center'
                        }}>

                        <p>
                            <i> {i18next.t('ConstatExternal')} </i> {props.dataItem.constat?.id}
                        </p>

                        {
                            dataItem.constatExt &&

                            <div style={{ width: `50%`,margin: 'auto', display: 'block' } }>
                                {canViewConstat &&
                                    <Button themeColor={"primary"}
                                        className="buttonMandat"
                                        icon="download"
                                        onClick={() => onClickCreatePDF(props.dataItem)}  >


                                    </Button>}
                                <Button themeColor={"primary"}
                                    className="buttonMandat"
                                    icon="eye"
                                    onClick={() => onClickViewConstat(props.dataItem)}  >
                                </Button>
                                <p></p>

                            </div>
                        }
                        {!dataItem.constatExt &&
                          
                            <div style={{ width: `100%`, margin:'auto', display: 'block' }}>
                            <Button themeColor={"primary"} className="buttonMandatNoPhoto"
                             
                                 onClick={() => onClickCreateConstat(props.dataItem)}  >
                                    {i18next.t('CreateConstat')}
                                </Button>
                            <p></p>
                            </div>
                        }


                    </td>
                    <td style={{
                        width: `50%`, textAlign: 'center'
                    }}>


                        <p>
                            <i> {i18next.t('ConstatInternal')} </i>
                        </p>

                        {dataItem.constatInt &&

                            <div style={{ width: `45%`, margin: 'auto', display: 'block' }}>
                                {canViewConstat &&
                                    <Button themeColor={"primary"}
                                        className="buttonMandat"
                                        icon="download"
                                        onClick={() => onClickCreatePDFInternal(props.dataItem)}  >


                                    </Button>}
                                <Button themeColor={"primary"}
                                    className="buttonMandat"
                                    icon="eye"
                                    onClick={() => onClickViewConstatInternal(props.dataItem)}  >
                                </Button>
                                <p></p>

                            </div>
                        }
                        {!dataItem.constatInt &&

                            <div style={{ width: `100%`, margin: 'auto', display: 'block' }}>
                            <Button themeColor={"primary"} className="buttonMandatNoPhoto"
                                
                                    onClick={() => onClickCreateConstatInternal(props.dataItem)}>
                                {i18next.t('CreateConstat')}
                                </Button>
                            <p></p>
                            </div>
                        }</td>
                </tr>
            </tbody>
        </table>

       
            {
                openFormTemplate && (
                    <TemplatePDFDialog
                        cancelPDF={handleCancelPDF}
                        okPDF={handleCreatePDF}
                        selectedValue={selectedValue}
                        dateReleve={dateReleve}
                        handleChange={handleChange}
                        handleChangeDateReleve={handleChangeDateReleve}
                    />
                )
            }
        </div>
        )
    




    
    
};


function MandatDetail({ t }) {
    let { id } = useParams();
    const [relMandatParcelles, setRelMandatParcelles] = useState([]);
    const [sort, setSort] = useState(initialSort);
    const [loading, setLoading] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [filter, setFilter] = useState(initialFilter);
    const [errors, setErrors] = useState('');
    const [title, setTitle] = useState('');
    
   
    const refresh = () => {
        if (updating)
            setUpdating(false);
        else
            setUpdating(true);
    };
   
    const [notificationState, setNotificationState] = useState({
        success: false,
        error: false,
        warning: false,
        info: false,
        none: false,
        notificationMessage: ""
    });

    const { success, error, warning, info, none, notificationMessage } = notificationState;
    const onFilterChange = (event) => {
        var filter = { logic: 'or', filters: [] };
        filter.filters.push({
            field: "fullTextSearch",
            operator: 'contains',
            value: event.target.value
        })
        setRelMandatParcelles(relMandatParcelles);
        setFilter(filter);
    };

    useEffect(() => {
        const fetchData = async () => {
            console.log('fetch mandat detail');
            
            MandatManager.getMandatDetail(id)
                .then(function (result) {

                    if (result) {
                        setTitle(result.fullTextSearch);
                        setRelMandatParcelles(result.relMandatParcelles);
                        setLoading(false);
                        setSort(sort);
                    }
                })
                .catch(function (error) {
                    setLoading(false);
                    setNotificationState({ ...notificationState, error: true, notificationMessage: error.message });
                    console.log(error);
                    setErrors(error.message);
                });
        };

       
        fetchData();

    }, []);

    useEffect(() => {
        setRelMandatParcelles(relMandatParcelles);

    }, [updating]);

    const expandChange = (event) => {
        let parcellesNew = relMandatParcelles.map((item) => {
            if (item.id === event.dataItem.id) {
                item.expanded = !event.dataItem.expanded;
            }
            return item;
        });
        setRelMandatParcelles(parcellesNew);
    };
    let contents = loading
        ? <p><em>{t('Loading')} </em></p>
        : errors.length == 0 ?
            <div>
                <NotificationGroup
                    style={{
                        right: 0,
                        bottom: 0,
                        alignItems: "flex-start",
                        flexWrap: "wrap-reverse",
                    }} >
                    <Fade>
                        {error && (
                            <Notification
                                type={{
                                    style: "error",
                                    icon: true,
                                }}
                                closable={true}
                                onClose={() => setNotificationState({ ...notificationState, error: false })}>
                                <span>{t(notificationMessage)}</span>
                            </Notification>
                        )}
                    </Fade>
                </NotificationGroup>

                <div style={{ marginLeft: 10 }}>
               
                    <Grid
                        name="ParcellesGrid"
                        data={filterBy(orderBy(relMandatParcelles, sort), filter)}
                        dataItemKey={"id"}
                        sort={sort}
                        sortable={true,
                        {
                            allowUnsort: false,
                            mode: "single"
                        }}

                        onSortChange={
                            e => {

                                setSort(e.sort);
                                setRelMandatParcelles(orderBy(relMandatParcelles, e.sort));
                                refresh();

                            }
                        }
                        filterable={true}
                        filter={filter}
                        filterOperators={filterOperators}
                        onFilterChange={(e) => {
                            setFilter(e.filter);
                        }}
                        detail={ParcelleDetailComponent}

                        expandField="expanded"
                        onExpandChange={expandChange}
                        resizable={true}
                    >
                        <GridToolbar>
                          

                            <input id='filter' onChange={onFilterChange} placeholder={t('Search')}
                                style={{
                                    marginLeft: 10, width: "250px", height: "25px"
                                }} />

                        </GridToolbar>

                        <Column field="parcelle.numero" filterable={false} title={t('Parcelle')} width={100} className="column-wrap"/>
                        <Column field="parcelle.adresse" filterable={false} title={t('Adresse')} />


                    </Grid>

                </div>

            </div>

            : <p><em>{t(errors)}</em></p>;

    return (
        <div>
            <h4 style={{ marginLeft: 10, marginTop: 5, marginBottom:5 }}>{title}</h4>

            {contents}

        </div>
    );
}
export default withTranslation()(MandatDetail);
