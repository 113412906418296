
import { deleteData, putData, getData, postData } from "../AccessAPI";


const UserManager = {

    async updateUser(user) {
        const result = await putData('/api/users/edit', user);
        return result;
    },
    async createUser(user) {
        let result = await postData('/api/users/create', user);
        return result;
    },
    async deleteUser(user) {
        const result = await deleteData('/api/users/delete/' + user.id);
        return result;
    },
    async getUsers() {     
        const result = await getData('/api/users');
        return result;       
    },
    async getUsersRead() {
        const result = await getData('/api/usersread');
        return result;
    },
   async GetUserRole(user) {
       const result = await getData('/api/users/getuserrole/' + user.id);
       return result;
    },
    async sendEmail(user) {
        let result = await postData('/api/mail/send', user);
        return result;
    },
    async getRoles() {
        const result = await getData('/api/roles');
        return result;
    }
    ,
    async getUserDetails(user) {
        const result = await getData('/api/users/getuserdetails/' + user.id);
        return result;
    },
    async passwordChange(userPasswordChange)
    {
        const result = await putData('/api/userschangepassword/changepassword', userPasswordChange);
        return result;
    }
    
    
}
export default UserManager;