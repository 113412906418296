
import { deleteData, putData, getData, postData } from "../AccessAPI";


const MandatManager = {

    
    async getMandats() {
        const result = await getData('/api/mandats');
        return result;
    } ,
    async getMandatDetail(id) {
        const result = await getData('/api/mandatdetail?id='+id);
        return result;
    },
    async createConstat(relMandatParcelleId) {
        var constat = { idRelMandatParcelle: relMandatParcelleId };
        let result = await postData('/api/constat/create', constat );
        return result;
    } ,
    async getConstatDetail(id, compress) {
        const result = await getData('/api/constat?id=' + id+"&compress="+compress);
        return result;
    },
    async getConstatIntDetail(id, compress) {
        const result = await getData('/api/constatint?id=' + id + "&compress=" + compress);
        return result;
    },
    async createFacade(facade) {
        
        let result = await postData('/api/facade/create', facade);
        return result;
    },
    async updateFacade(facade) {

        let result = await putData('/api/facade/update', facade);
        return result;
    },
    async getFacadeDetail(id) {
        const result = await getData('/api/facade?id=' + id);
        return result;
    },
    //async getFacadeNavigationDetail(id) {
    //    const result = await getData('/api/facade/getFacadeNavigationDetail', id);
    //    return result;
    //},
    async updateConstat(constat) {
        let result = await putData('/api/constat/update', constat);
        return result;
    },
    async createPDF(constatId) {
        let result = await postData('/api/pdf/create', constatId);
        return result;
    },
    async createFissure(fissureToCreate) {
       
        let result = await postData('/api/fissure/create', fissureToCreate);
        return result;
    },
    async updateDegat(degat) {

        let result = await putData('/api/degat/update', degat);
        return result;
    },
    async updateFissure(fissure) {

        let result = await putData('/api/fissure/update', fissure);
        return result;
    },
    async getFissureDetail(id) {
        const result = await getData('/api/fissure?id=' + id);
        return result;
    },
    async getDegatDetail(id) {
        const result = await getData('/api/degat?id=' + id);
        return result;
    },
    async getOrientationTypeList() {
        return getCatalogValuesList("orientationtype");
    },
    async getPositionTypeList() {
        return getCatalogValuesList("positiontype");
    },
    async getCatalogValuesList(catalogName) {
        const result = await getData('/api/catalogs/getcatalogvalues/' + catalogName);
        return result;
    },
    async deleteFacade(facade) {
        let result = await deleteData('/api/facade/delete/' + facade.id);
        return result;
    },
    async deleteFissure(fissure) {
        let result = await deleteData('/api/fissure/delete/' + fissure.id);
        return result;
    },
    async deleteDegat(degat) {
        let result = await deleteData('/api/degat/delete/' + degat.id);
        return result;
    },
    async createConstatInternal(relMandatParcelleId) {
        var constat = { idRelMandatParcelle: relMandatParcelleId };
        let result = await postData('/api/constatint/create', constat);
        return result;
    },
    async deleteZone(zone) {
        let result = await deleteData('/api/zone/delete/' + zone.id);
        return result;
    },
    async getZoneDetail(id, compress) {
        const result = await getData('/api/zone?id=' + id + "&compress=" + compress);
        return result;
    },
    async updateZone(zone) {
        let result = await putData('/api/zone/update', zone);
        return result;
    },
    async createZone(zone) {
        let result = await postData('/api/zone/create', zone);
        return result;
    },
    async deletePiece(piece) {
        let result = await deleteData('/api/piece/delete/' + piece.id);
        return result;
    },
    async getPieceDetail(id) {
        const result = await getData('/api/piece?id=' + id);
        return result;
    },
    async updatePiece(piece) {
        let result = await putData('/api/piece/update', piece);
        return result;
    },
    async createPiece(piece) {
        let result = await postData('/api/piece/create', piece);
        return result;
    },
    async deleteMur(mur) {
        let result = await deleteData('/api/mur/delete/' + mur.id);
        return result;
    },
    async getMurDetail(id) {
        const result = await getData('/api/mur?id=' + id);
        return result;
    },
    async updateMur(mur) {
        let result = await putData('/api/mur/update', mur);
        return result;
    },
    async createMur(mur) {
        let result = await postData('/api/mur/create', mur);
        return result;
    },
    async updateZone(zone) {

        let result = await putData('/api/zone/update', zone);
        return result;
    },
    
}
export default MandatManager;