import { useState, useEffect } from "react";
import { withRouter } from './withRouter';
import { Menu, MenuItem } from "@progress/kendo-react-layout";
import SessionManager from "./auth/SessionManager";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';
const MenuNavContainer = (props) => {
    const [classs, setClasss] = useState("menuItem");
    const [selectedClasss, setSelectedClasss] = useState("selectedMenuItem");
    const [selectedItem, setSelectedItem] = useState("/");

    const onSelect = (event) => {
        //setClasss("selectedMenuItem");
        setSelectedItem(event.item.data.route);
        props.navigate(event.item.data.route);  
        SessionManager.setSelectedRoute(event.item.data.route);
    };
    useEffect(() => {       
        var route = SessionManager.getSelectedRoute();
        if (route)
            setSelectedItem(route);
        else {
            setSelectedItem("/");
            SessionManager.setSelectedRoute(route);
        }
    }, []);
    if (isMobile) {
        return (
            <div style={{
                marginTop: 10,
            }}>
                <Menu onSelect={onSelect}>
                    <MenuItem
                        text={i18next.t('Mandats')}
                        icon="clipboard"
                        data={{
                            route: "/",
                        }}
                        cssClass={selectedItem === "/" ? selectedClasss : classs}

                    />


                </Menu>
                <div
                    style={{
                        marginTop: 5, marginLeft: 10, marginRight: 5

                    }}
                >
                    {props.children}
                </div></div>
        )
    } else {
        return (
            <div style={{
                marginTop: 10,
            }}>
                <Menu onSelect={onSelect}>
                    <MenuItem
                        text={i18next.t('Mandats')}
                        icon="clipboard"
                        data={{
                            route: "/",
                        }}
                        cssClass={selectedItem === "/" ? selectedClasss : classs}

                    />

                    <MenuItem
                        text={i18next.t('Users')}
                        icon="user"
                        data={{
                            route: "/users",
                        }}
                        cssClass={selectedItem === "/users" ? selectedClasss : classs}

                    />
                </Menu>
                <div
                    style={{
                        marginTop: 5, marginLeft: 10,marginRight:5

                    }}
                >
                    {props.children}
                </div>
            </div >)
    }

};
export default withRouter(MenuNavContainer);