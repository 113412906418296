import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, FormElement } from "@progress/kendo-react-form";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { RadioButton } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";

function TemplatePDFDialog(props) {
    const [title, setTitle] = React.useState("TemplatePDF");
   


    let contents =
        <Dialog title={i18next.t(title)} onClose={props.cancelPDF}>
            <Form
                initialValues={props.selectedValue}
                onSubmit={props.onSubmit}
                render={() => (
                    <div>
                        <div>{i18next.t('DateReleve')}
                            <DatePicker name="DateReleve" placeholder={i18next.t('ChooseAdate')}
                                format={"dd.MM.yyyy"} adaptive={true} PopupWidth="300px" width={250} 
                            onChange={props.handleChangeDateReleve}   /></div>
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="SignatureMetriX"
                            checked={props.selectedValue === "SignatureMetriX"}
                            label={i18next.t('SignatureMetriX')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="SignaturesMetriXPropr"
                            checked={props.selectedValue === "SignaturesMetriXPropr"}
                            label={i18next.t('SignaturesMetriXPropr')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="SignaturesMetriXMandant"
                            checked={props.selectedValue === "SignaturesMetriXMandant"}
                            label={i18next.t('SignaturesMetriXMandant')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="SignaturesMetriXMandantPropr"
                            checked={props.selectedValue === "SignaturesMetriXMandantPropr"}
                            label={i18next.t('SignaturesMetriXMandantPropr')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <RadioButton
                            name="group1"
                            value="23MTX003"
                            checked={props.selectedValue === "23MTX003"}
                            label={i18next.t('23MTX003')}
                            onChange={props.handleChange}
                        />
                        <br /> <br />
                        <button
                            type={"submit"}
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={props.cancelPDF} >
                            {i18next.t('Cancel')}
                        </button>&nbsp;&nbsp;
                        <button
                            type={"submit"}
                            onClick={props.okPDF} 
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"  >
                            {i18next.t('OK')}
                        </button>
                       

                        </div>
                          
                )}
            />
        </Dialog>

    return (
        <div>
            {contents}

        </div>

    );
};
export default withTranslation()(TemplatePDFDialog);